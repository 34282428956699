import { useGtm } from '@gtm-support/vue-gtm';

class GoogleTagManager {

  /**
   * pushes event to data layer
   * @param {string} eventName
   * @param {string} [value=''] - any tracking param (mostly service id)
   * @returns {any}
   */
  public triggerEvent(eventName: string, value: string = ''): void {
    const gtm = useGtm();

    if(gtm?.enabled) {
      window.dataLayer?.push({
        event: eventName,
        value: value
      });
    }
  }
}

export default new GoogleTagManager();
